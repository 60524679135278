webClient.directive('ngPurchaseSummary', ['$window', '$timeout', 'templateService', 'tseAirResultsHelper', 'tseApiClient',
    function ($window, $timeout, templateService, tseAirResultsHelper, tseApiClient) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            bookingdata: "=",
            showpaxes: "=",
            showprint: "=",
            newversion: "=",
            showaccomodationinfo: "=",
            showaccomodationinfopricedetail: "=",
            parentaccommodation: "=",
            fullalertcabinmessage: "=",
            disablerequestsummary: "=",
            disableaddluggages: "=",
            displaykeepflightbutton: "=",
            hidealertmessageto: "=",
            hidepricecondition:"="
        };
        directive.link = function ($scope, $element) {
            templateService.compile($scope, $element, 'tpl/_PurchaseSummary.html');
            //Initialize Variables
            //$scope.enableFreeFlex = window.enableFreeFlex === true;
            $scope.getUrl = $window.getUrl;
            $scope.siteType = $window.site.type;
            $scope.getStaticFolder = $window.getStaticFolder;
            $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
            $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
            window.airHelper = tseAirResultsHelper;
            $scope.isWonderBox = typeof $window.isWonderBox !== 'undefined' ? $window.isWonderBox : false;
            $scope.isServair = window.site.partnerId === "servair";
            $scope.isb2c = window.site.partnerId === "b2c";
            $scope.isb2b = $scope.siteType === 2;
            $scope.isAirbus = $window.isAirbus;
            $scope.bkgData = $scope.bookingdata;
            $scope.lng = $window.lng;
            $scope.locale = $window.lng.substring(0, 2);
            $scope.partnerName = $window.site.partnerId;
            
            $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
            $scope.paxtype = ["Adulte", "Enfant", "Bébé"];
            $scope.paxamount = [$scope.bookingdata.request.adults, $scope.bookingdata.request.childs, $scope.bookingdata.request.infants];
            $scope.totalPaxes = $scope.bookingdata.request.adults + $scope.bookingdata.request.childs + $scope.bookingdata.request.infants;
            $scope.hasWarningRoundTrip = false;
            if ($window.enableUpSellProducts) {
                $scope.checkIfHasAccommodations = true;
            }
            $scope.enableUpSellProducts = $window.bookingContainAccommodation || $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
            $scope.isProductTO = $scope.siteType === 2 && $scope.bookingdata.purchaseResponse.isProductTo;
            var fareNotes = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes;
            var showNotes = fareNotes && fareNotes.length > 0 && fareNotes.every(function (f, i) { return f.note != null });
            $scope.showNotes = $scope.siteType === 2 && showNotes && !$scope.enableUpSellProducts;

            $scope.displayBrandedFaresSummary = $window.displayBrandedFaresSummary && ($scope.bookingdata.purchaseResponse.brandedFaresServices && $scope.bookingdata.purchaseResponse.brandedFaresServices.length > 0);

            $scope.auxItineraries = $window.objectClone($scope.bookingdata.purchaseResponse.itineraries);
            $scope.auxItineraries = $scope.auxItineraries.groupByKey("key");

            $scope.flightTypeIsOneWay = $scope.bookingdata.purchaseResponse.tripType === 1;
            $scope.flightTypeIsRoundTrip = $scope.bookingdata.purchaseResponse.tripType === 2;
            $scope.flightTypeIsOpenJaw = $scope.bookingdata.purchaseResponse.tripType === 3;
            $scope.flightTypeIsMultiDestinationation = $scope.bookingdata.purchaseResponse.tripType === 4;

            //Flags
            $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');
            $scope.hideMessageTO = $window.hideMessageTO;
            $scope.enableBrandedFares = $window.enableBrandedFares;
            $scope.firstTime = true;
            var siteTypeIsB2b = $scope.siteType === 2;
            $scope.isB2b = $window.site.type == 2;
            $scope.enableSeatMap = !(typeof $window.accountNumber !== 'undefined' && typeof $window.seatMapAccountNumber !== 'undefined' && $window.accountNumber != $window.seatMapAccountNumber);
            $scope.isKiwi = $scope.bookingdata.purchaseResponse.kiwi ? true : false;

            $scope.hasFlexies = false;
            $scope.showseatmapwarning = false;
            $scope.currentNotesKey = null;
            $scope.currentProposalsIndex = null;

            $scope.directFlight = true;
            $scope.fareNoteNumber = 0;

            $scope.isOpened = false;
            $scope.enableFlexy = window.enableFlexy;
            $scope.enableFreeFlexPartner = window.enableFreeFlex;
            $scope.lastFlexyContext = document.location.href.indexOf("airpaymentresponse") > -1;
            $scope.bookingdata.purchaseResponse.itineraries.forEach(function (itinerary) {
                if (itinerary.segments.length > 1) {
                    $scope.directFlight = false;
                }
            });
            var paxesNumber = $scope.bookingdata.purchaseResponse.adult + $scope.bookingdata.purchaseResponse.child + $scope.bookingdata.purchaseResponse.infant;
            $scope.priceToDisplay = $scope.bookingdata.purchaseResponse.proposals[0].totalAmount.value + $scope.bkgData.purchaseResponse.proposals[0].totalAgencyMargin.value + $scope.bkgData.purchaseResponse.proposals[0].additionalAmount.value;
            $scope.bookingdata.purchaseResponse.proposals[0].totalAmountPerPax = airHelper.getNumberObject($scope.priceToDisplay / paxesNumber);

            $scope.directFlightNotFound = $window.sessionStorage.getItem('direct') === 'on' && !$scope.directFlight;
            $scope.flightAutoSelected = typeof ($scope.bkgData.purchaseResponse.additionalData) !== 'undefined' && typeof ($scope.bkgData.purchaseResponse.additionalData.basketFlightMessages) !== 'undefined';
            $scope.alertFlexyPriceChangedTimeout = 0;
            $scope.oldBrandedIndex = 0;
            $scope.drawerNotesOpened = false;
            $scope.currentObjNotes = null;
            $scope.currentItkeyNotes = null;

            $scope.extimePoints = 0;

            try {
                var flexies = $scope.bookingdata.purchaseResponse.proposals[0].flexies;
                Object.keys(flexies).forEach(function (key) {
                    if (/^00004|00005|00006$/i.test(flexies[key].flexyReference)) {
                        $scope.hasFlexies = true;
                    }
                });
            }
            catch (e) {
                $scope.hasFlexies = false;
            }
            
            $scope.isFlexiesV4 = false;
            $scope.showFlexy = false;
            try {
                var flexies = $scope.bookingdata.purchaseResponse.proposals[0].flexies;
                Object.keys(flexies).forEach(function (key) {
                    if (parseInt(flexies[key].flexyReference) >= 12) {
                        $scope.isFlexiesV4 = true;
                    }
                    if (flexies[key].flexyTypology == 'FLEXY') {
                        $scope.showFlexy = true;
                    }
                });
            }
            catch (e) {
                
            }

            $scope.hasFlexiesSelectable = false;
            try {
                var flexies = $scope.bookingdata.purchaseResponse.proposals[0].flexies;
                Object.keys(flexies).forEach(function (key) {

                    if (flexies[key].selectable == true) {
                        $scope.hasFlexiesSelectable = true;
                    }
                });
            }
            catch (e) {

            }
            
            getBundleWidget("mrfly-widget-request-recap.js").done(function () {
                console.log("Loading done");
                //set params do mapping

                var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

                airSearchRequest.adultNumber = $scope.bookingdata.request.adults;
                airSearchRequest.childNumber = $scope.bookingdata.request.childs;
                airSearchRequest.infantNumber = $scope.bookingdata.request.infants;
                airSearchRequest.isOneWay = $scope.bookingdata.request.itineraries.length == 1;
                airSearchRequest.isRoundTrip = $scope.bookingdata.request.itineraries.length == 2 && $scope.bookingdata.request.itineraries[0].origin.cityIATA.localeCompare($scope.bookingdata.request.itineraries[1].destination.cityIATA) === 0;
                airSearchRequest.isOpenJaw = $scope.bookingdata.request.itineraries.length === 2 && $scope.bookingdata.request.itineraries[0].origin.cityIATA.localeCompare($scope.bookingdata.request.itineraries[1].destination.cityIATA) !== 0;
                airSearchRequest.isMultiDestination = $scope.bookingdata.request.itineraries.length > 2;
                airSearchRequest.cabinClass = $scope.bookingdata.request.cabinClass;

                //Map itineraries
                airSearchRequest.itineraries = [];
                $scope.bookingdata.purchaseResponse.itineraries.forEach(function (itinerary) {
                    var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                    ins.date = itinerary.date;

                    ins.departureAirportIata = itinerary.origin.airportIata;
                    ins.departureCityIata = itinerary.origin.cityIata;
                    ins.departureName = itinerary.origin.cityName;

                    ins.arrivalAirportIata = itinerary.destination.airportIata;
                    ins.arrivalCityIata = itinerary.destination.cityIata;
                    ins.arrivalName = itinerary.destination.cityName;

                    airSearchRequest.itineraries.push(ins);
                });
                mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
                mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
                mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
            });

            getBundleWidget("mrfly-widget-itinerary-recap.js").done(function () {
                console.log("Loading done");
                var atLeastOneItineraryHasCo2Data = false;
                $scope.bookingdata.purchaseResponse.itineraries.forEach(function (it, key) {
                    it.atLeastOneItineraryHasCo2Data = false;

                    if (window.enableCO2) {
                        if (typeof ($scope.bookingdata.co2Data) !== 'undefined') {
                            var co2Data = $scope.bookingdata.co2Data[it.origin.cityIata + it.destination.cityIata];
                            if (co2Data != null && co2Data.hasAllSegmentsData) {
                                atLeastOneItineraryHasCo2Data = true;
                                it.co2Data = co2Data;
                            }
                        }
                    }

                    $scope.bookingdata.purchaseResponse.itineraries[key].isProductMasstock = $scope.bookingdata.purchaseResponse.masstock;
                    $scope.bookingdata.purchaseResponse.itineraries[key].isProductTo = $scope.isProductTO;
                });

                if (atLeastOneItineraryHasCo2Data) {
                    $scope.bookingdata.purchaseResponse.itineraries.forEach(function (it, key) {
                        it.atLeastOneItineraryHasCo2Data = true;
                    });
                }

                // Check if seats were selected by passengers
                var selectedSeatsKey = 'selectedSeats_' + $scope.bookingdata.purchaseResponse.purchaseId;
                var sessionSeats = window.sessionStorage.getItem(selectedSeatsKey);
                var atLeastOneSeatSelected = false;
                if (sessionSeats != null) {
                    var selection = JSON.parse(sessionSeats);
                    angular.forEach(selection, function (segment, key) {
                        var content = selection[key];
                        if (Object.keys(content).length === 0 && Object.getPrototypeOf(content) === Object.prototype) {
                        } else {
                            atLeastOneSeatSelected = true;
                        }
                    });
                }
                $scope.showseatmapwarning = atLeastOneSeatSelected;

                $scope.$apply();
                //set params do mapping
                mrflyWidgets['mrfly-widget-itinerary-recap'].locale = $window.lng.substring(0, 2);
                mrflyWidgets['mrfly-widget-itinerary-recap'].language = $window.lng;
                mrflyWidgets['mrfly-widget-itinerary-recap'].updateItineraries($scope.bookingdata.purchaseResponse.itineraries);
                mrflyWidgets['mrfly-widget-itinerary-recap'].requestcabinclass = $scope.bookingdata.request.cabinClass;
                mrflyWidgets['mrfly-widget-itinerary-recap'].currentCabinClass = $scope.currentCabinClass;
                mrflyWidgets['mrfly-widget-itinerary-recap'].cabinclasses = $scope.bookingdata.cabinclasses;
                mrflyWidgets['mrfly-widget-itinerary-recap'].showbaggages = $scope.showpaxes != true;
                mrflyWidgets['mrfly-widget-itinerary-recap'].fares = $scope.bookingdata.purchaseResponse.proposalsRequested.fares;
                mrflyWidgets['mrfly-widget-itinerary-recap'].enableaddluggages = $scope.disableaddluggages ? false : true;
                mrflyWidgets['mrfly-widget-itinerary-recap'].isKiwi = $scope.bookingdata.purchaseResponse.kiwi ? true : false;

                mrflyWidgets['mrfly-widget-itinerary-recap'].$on('showPopInSelfConnect', function () {
                    mrflyWidgets['reducModal'].$store.commit("showModal");
                });

            });
            //free flex
            if ($scope.bookingdata.purchaseResponse.proposalsRequested.flexies && !$scope.isServair) {
                var result = $scope.bookingdata.purchaseResponse.proposalsRequested.flexies;
                /*$scope.enableFreeFlex = false;*/
                $scope.enableFlex75 = false;
                $scope.enableTotalFlex = false;
                $scope.enableFlex50 = false;
                $scope.enableFlex100 = false;
                $scope.isflexyb2b = false;
                $scope.flexyTotalPrice = 0;
                $scope.flexyDataItem = 0;
                for (var item in result) {
                    $scope.flexyDataItem++;
                    switch (result[item].flexyReference) {
                        case '00001':
                            $scope.isflexyb2b = true;
                            $scope.flexyTotalPrice = result[item].totalPrice;
                            break;
                        case '00004':
                            $scope.enableFreeFlex = window.enableFreeFlex && result[item].selectable;
                            $scope.flexyTotalPrice = result[item].totalPrice;
                            break;
                        case '00005':
                            $scope.enableTotalFlex = window.enableFreeFlex && result[item].selectable;
                            $scope.flexyTotalPrice = result[item].totalPrice;
                            break;
                        case '00006':
                            $scope.enableTotalFlex = window.enableFreeFlex && result[item].selectable;
                            $scope.flexyTotalPrice = result[item].totalPrice;
                            break;
                        case '00009':
                            $scope.enableFlex50 = window.enableFreeFlex && result[item].selectable;
                            $scope.flexyTotalPrice = result[item].totalPrice;
                            break;
                        case '00010':
                            $scope.enableFlex100 = window.enableFreeFlex && result[item].selectable;
                            $scope.flexyTotalPrice = result[item].totalPrice;
                            break;
                        default:
                            console.log(result[item].flexyReference)
                    }
                }
            }
            if ($scope.enableUpSellProducts) {
                $scope.enableFreeFlex = false;
                $scope.isFlexiesV4 = true;
            }
            $scope.pricePerAdult = $scope.bookingdata.purchaseResponse.proposalsRequested.fares[0].totalAmount.value;
            $scope.totalPrice = $scope.bookingdata.purchaseResponse.proposalsRequested.totalAmount.value;

            $scope.closeDrawerNotes = function () {
                if ($scope.drawerNotesOpened) {
                    $scope.drawerNotesOpened = false;
                }
                var localCurrentProposalIndex = $scope.bookingdata.purchaseResponse.proposalsRequested.index;
                $scope.CloseNotes($scope.currentObjNotes, $scope.currentNotesKey + "-" + localCurrentProposalIndex);
            };

            //Taxes details
            $scope.createTaxesDetailModal = function (key, paxFares, itineraries) {

                var marketingCarriers = [];

                itineraries.each(function (value, key) {
                    marketingCarriers.push(value.marketingCarrier.iata);
                });

                $scope.$emit("displayTaxesDetailsClick", { key: key.toString(), paxFares: paxFares, marketingCarriers: marketingCarriers });
            };

            //Taxes details
            $scope.createLuggageDetailModal = function (bagType, itineraries) {

                var marketingCarriers = [];

                itineraries.each(function (value, key) {
                    marketingCarriers.push(value.marketingCarrier.iata);
                });

                $scope.$emit("displayLuggageDetailsClick", { bagType: bagType, marketingCarriers: marketingCarriers });
            };

            //Taxes Seat details
            $scope.createSeatDetailModal = function (seat, itineraries) {

                var marketingCarriers = [];

                itineraries.each(function (value, key) {
                    marketingCarriers.push(value.marketingCarrier.iata);
                });

                $scope.$emit("displaySeatDetailsClick", { seat: seat, marketingCarriers: marketingCarriers });
            };

            //Animate chevron
            $scope.setOpened = function() {
                $scope.isOpened = !$scope.isOpened;
            }

            $scope.keepFlightRedirect = function () {
                document.getElementById('keepFlight').classList.add('__loading');
                $window.FLoading({ vAction: "show" });
                $window.location.href = $window.site.currentPageUrl.keepFlight;
            };

            $scope.updateTemplate = function () {
                //Additional baggages
                $scope.addtionalBaggages = [];
                $scope.addBaggages = [];
                if ($scope.bookingdata && $scope.bookingdata.bookingRequest) {
                    $scope.bookingdata.bookingRequest.passengers.each(function (p) {
                        for (var item in p.baggageByItinerary) {
                            if (p.baggageByItinerary[item].quantity > 0) {
                                var auxOrigin = "";
                                var auxDestination = "";

                                $scope.bookingdata.purchaseResponse.itineraries.any(function (it) {
                                    if (it.key == item) {
                                        auxOrigin = it.origin.cityIata;
                                        auxDestination = it.destination.cityIata;
                                        return true;
                                    }
                                });

                                var auxBag = {
                                    quantity: p.baggageByItinerary[item].quantity,
                                    weight: p.baggageByItinerary[item].weight,
                                    itineraryKey: item,
                                    origin: auxOrigin,
                                    destination: auxDestination,
                                    price: p.baggageByItinerary[item].totalPrice.value,
                                    key: p.baggageByItinerary[item].key,
                                    location: p.baggageByItinerary[item].location,
                                    cabinQuantity: p.baggageByItinerary[item].cabinQuantity,
                                    checkedQuantity: p.baggageByItinerary[item].checkedQuantity,
                                    cabinWeight: p.baggageByItinerary[item].cabinWeight,
                                    checkedWeight: p.baggageByItinerary[item].checkedWeight,
                                    margin: p.baggageByItinerary[item].margin,
                                };

                                $scope.addtionalBaggages.push(auxBag);
                            }
                        }
                    });
                }

                var baggagesItinerary = $scope.addtionalBaggages.groupByKey('key');

                //Comprobar estar rutina
                for (var key in baggagesItinerary) {
                    if (!$window.isArray(baggagesItinerary[key]))
                        var auxBaggage = baggagesItinerary[key];
                    else
                        var auxBaggage = baggagesItinerary[key][0];

                    if (auxBaggage.quantity > 0) {
                        var quantity = baggagesItinerary[key].length != undefined ? baggagesItinerary[key].length : 1;

                        var bgType = {
                            price: auxBaggage.price,
                            quantity: auxBaggage.quantity,
                            weight: auxBaggage.weight,
                            text: auxBaggage.text,
                            key: key,
                            typeQuantity: baggagesItinerary[key].length != undefined ? baggagesItinerary[key].length : 1,
                            totalQuantity: quantity * auxBaggage.quantity,
                            totalPrice: quantity * auxBaggage.price,
                            origin: auxBaggage.origin,
                            destination: auxBaggage.destination,
                            location: auxBaggage.location,
                            cabinQuantity: auxBaggage.cabinQuantity,
                            checkedQuantity: auxBaggage.checkedQuantity,
                            cabinWeight: auxBaggage.cabinWeight,
                            checkedWeight: auxBaggage.checkedWeight,
                            margin: auxBaggage.margin,
                        };
                        $scope.addBaggages.push(bgType);
                    }
                }

                if ($scope.enableSeatMap) {
                    //Seats
                    $scope.selectedSeats = [];

                    var setmapKey = 'selectedSeats_' + $scope.bookingdata.purchaseResponse.purchaseId;
                    console.log(setmapKey);

                    var sessionSeats = window.sessionStorage.getItem(setmapKey);
                    if (sessionSeats != null) {
                        var selection = JSON.parse(sessionSeats);

                        angular.forEach($scope.bookingdata.purchaseResponse.itineraries, function (itinerary, key) {
                            angular.forEach(itinerary.segments, function (segment, key) {
                                var seat = { price: 0, quantity: 0, providerPrice: 0, marginValue: 0 };
                                seat.segmentName = segment.departure.cityIata + "-" + segment.arrival.cityIata;
                                seat.details = [];
                                angular.forEach(selection[segment.key], function (select, key) {
                                    seat.details.push(select);
                                    seat.price += select.price;
                                    seat.providerPrice += select.providerPrice;
                                    seat.marginValue += select.marginValue;
                                    seat.quantity++;
                                });

                                $scope.selectedSeats.push(seat);
                            });
                        });
                    }
                }
                $scope.totalFlexSelected = $scope.bookingdata.totalFlexSelected;
                $scope.hasFlexy = (window.sessionStorage.getItem("hasFlexy") + "") === "true";
                $scope.totalFlexAmount = $scope.bookingdata.totalFlexAmount;
                $scope.textFlexSelected = $scope.bookingdata.textFlexSelected;
                $scope.titleDescriptionFlexyItems = $scope.bookingdata.titleDescriptionFlexyItems;
                $scope.flexyReference = $scope.bookingdata.flexyReference;
                //if (typeof $scope.totalFlexSelected !== 'undefined') {
                //    var repatriamentInsurance = "";
                //    var flexDatalength=window.sessionStorage.getItem("inssurance.flex.flexDatalength");
                //    if (!$scope.totalFlexSelected) {
                //        if (flexDatalength == 3) {
                //            repatriamentInsurance = "00004";
                //        } else {
                //            repatriamentInsurance = "00003";
                //        }
                       
                //    }
                //    if ($scope.bookingdata.flexyReference == "00002" || $scope.bookingdata.flexyReference == "00003" || $scope.bookingdata.flexyReference == "00004") {
                //        repatriamentInsurance = "00004";
                //    }
                //    if ($scope.bookingdata && $scope.bookingdata.insurancesSelected && (typeof $scope.bookingdata.insurancesSelected.isRepatriament !== 'undefined') && $scope.bookingdata.insurancesSelected.isRepatriament.amount > 0 && $scope.bookingdata.insurancesSelected.isRepatriament.reference != repatriamentInsurance ) {
                //        $scope.bookingdata.insurancesSelected.isRepatriament.amount = 0;
                //        window.sessionStorage.setItem("inssurance.repatriation.selected", false);
                //    }
                //}

                $scope.updateTotal();
                $timeout(function () {
                    $scope.$apply();//forces layout rendering but can raise an exception if not called in async method
                });

                var fareNotes = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes;
                var showNotes = fareNotes && fareNotes.length > 0 && fareNotes.every(function (f, i) { return f.note != null });
                $scope.showNotes = $scope.siteType === 2 && showNotes && !$scope.enableUpSellProducts;

                $scope.$emit("onBookingSummaryUpdateCompleted", $scope.bookingdata);
            };

            getBundleWidget("mrfly-widget-modal.js").done(function () {

            }).fail(function (jqxhr, settings, exception) {
                console.log(exception);
            });

            $scope.bookingdata.purchaseResponse.itineraries.forEach(function (itinerary) {
                var fareNote = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes.getByKeyValue('key', itinerary.key);
                if (fareNote) {
                    $scope.fareNoteNumber++;
                    console.log('HeRE');
                }
            });

            $scope.HasNotes = function (itKey) {
                var fareNote = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes.getByKeyValue('key', itKey);
                if (fareNote) {
                    return true;
                }
                return false;
            };

            $scope.IsTheModalShown = function (itKey,proposalIndex) {
                return $scope.currentNotesKey == itKey && $scope.currentProposalsIndex == proposalIndex;
            };

            $scope.ProposalHasNotes = function (itKey, proposalIndex) {
                var fareNote = $scope.bookingdata.purchaseResponse.proposals[proposalIndex].fareNotes.getByKeyValue('key', itKey);
                if (fareNote) {
                    return true;
                }
                return false;
            };

            $scope.CloseNotes = function (obj, fareNotesModalKey) {
                if (obj && obj.iPop) {
                    if ($scope.bookingdata.purchaseResponse.proposalsRequested) {
                        $window.FLoading({ vAction: "show" });
                        window.mrflyWidgets[fareNotesModalKey].$store.commit("hideModal");
                        $scope.currentNotesKey = null;
                        $scope.currentProposalsIndex = null;
                        $window.FLoading({ vAction: "hide" });
                    }
                }
            };

            //Get Fares Notes
            $scope.GetNotes = function (obj, itKey) {
                var localCurrentProposalIndex = $scope.bookingdata.purchaseResponse.proposalsRequested.index;
                if ($scope.currentNotesKey != null && $scope.currentNotesKey != itKey && window.mrflyWidgets[$scope.currentNotesKey + "-" +localCurrentProposalIndex].$store.state.showModal) {
                    $scope.CloseNotes(obj, $scope.currentNotesKey + "-" + localCurrentProposalIndex);
                    $scope.drawerNotesOpened = false;
                } else if ($scope.currentNotesKey != null && $scope.currentNotesKey == itKey && window.mrflyWidgets[itKey + "-" + localCurrentProposalIndex].$store.state.showModal){
                    $scope.CloseNotes(obj, itKey + "-" + localCurrentProposalIndex);
                    $scope.drawerNotesOpened = false;
                    return;
                }
                if (obj && obj.iPop) {
                    $scope.drawerNotesOpened = true;
                    $scope.currentObjNotes = obj;
                    $scope.currentItkeyNotes = itKey;
                    // var iPropPre = $("#" + obj.iPop + " pre");
                    // iPropPre.text("");

                    if ($scope.bookingdata.purchaseResponse.proposalsRequested) {
                        $window.FLoading({ vAction: "show" });
                        // var note = "";
                        // var fareNoteAux = $scope.bookingdata.purchaseResponse.proposalsRequested.fareNotes.getByKeyValue("key", itKey);

                        // if (fareNoteAux != null)
                        //     note = fareNoteAux.note;

                        // if (note != null && note != "")
                        //     iPropPre.text(note);

                        window.mrflyWidgets[itKey + "-" + localCurrentProposalIndex].$store.commit("showModal");
                        $scope.currentNotesKey = itKey;
                        $scope.currentProposalsIndex = localCurrentProposalIndex;
                        // $scope.FPop(null, obj);
                        $window.FLoading({ vAction: "hide" });
                    }

                }
            };

            //Update Total amounts
            $scope.updateTotal = function () {
                var includeBaggage = true;
                if ($scope.isServair) {
                    includeBaggage = false;
                }

                var rsTotals = $scope.updateTotals($scope.bookingdata.purchaseResponse.proposalsRequested.totalAmount.value, includeBaggage);
                var rsTotalsOld = $scope.updateTotals($scope.bookingdata.purchaseResponse.proposalsRequested.oldTotalAmount.value, true);
                $scope.prixTotal = rsTotals[0];
                $scope.oldPrice = rsTotalsOld[0];

                if ($scope.bookingdata.purchaseResponse.proposalsRequested.totalSubventionAmount != null) {
                    $scope.totalSubventionAmount = $scope.bookingdata.purchaseResponse.proposalsRequested.totalSubventionAmount;
                }
                else {
                    $scope.totalSubventionAmount = airHelper.getNumberObject(parseFloat(($scope.bookingdata.purchaseResponse.proposalsRequested.oldTotalAmount.value - $scope.bookingdata.purchaseResponse.proposalsRequested.totalAmount.value).toFixed(2)));
                }

                $scope.prixTotalWithoutMargin = rsTotals[1];
                $scope.bookingdata.prixTotal = $scope.prixTotal;
                $scope.bookingdata.prixTotalWithoutMargin = $scope.prixTotalWithoutMargin;

                console.log($scope.prixTotal);

                var flexies = $scope.bookingdata.purchaseResponse.proposalsRequested.flexies;
                var doesFlexiesPricesChanged = false;
                var doesBrandedFlexyChanged = false;
                Object.keys(flexies).forEach(function (key) {
                    if (parseInt(flexies[key].flexyReference) >= 12 || $scope.showFlexy) {
                        var oldValue = flexies[key].totalPrice;
                        var newTotal = 0;
                        var totalQuantityFlexies = 0;

                        for (var i = 0; i < flexies[key].prices.length; i++) {
                            var flexyPrice = flexies[key].prices[i];
                            var luggagesTotal = $scope.getLuggageAveragePriceByPaxType(flexyPrice.paxType);
                            var seatsTotal = $scope.getSeatAveragePriceByPtc(flexyPrice.ptc);
                            var grossValueWithLuggageAndSeats = flexyPrice.gross + (luggagesTotal / flexyPrice.quantity) + (seatsTotal / flexyPrice.quantity);
                            flexyPrice.priceAmount = Math.ceil(grossValueWithLuggageAndSeats / 100 * flexyPrice.priceValue);
                            flexyPrice.totalFlexyAmount = flexyPrice.priceAmount * flexyPrice.quantity;

                            totalQuantityFlexies += flexyPrice.quantity;
                            newTotal += flexyPrice.totalFlexyAmount;
                        }

                        if (oldValue !== newTotal) {
                            flexies[key].totalPrice = newTotal;
                            flexies[key].averagePrice = Math.ceil(flexies[key].totalPrice / totalQuantityFlexies);  
                            doesFlexiesPricesChanged = true;
                        }

                        if ($scope.oldBrandedIndex != $scope.bookingdata.purchaseResponse.proposalsRequested.index) {
                            $scope.oldBrandedIndex = $scope.bookingdata.purchaseResponse.proposalsRequested.index;
                            doesBrandedFlexyChanged = true;
                        }
                    }
                });

                if (window.partnerName == "adpmobile" || window.partnerName == "adp") {
                    $scope.extimePoints = $scope.prixTotal.value;
                    $scope.bookingdata.purchaseResponse.proposalsRequested.fares.forEach(function (fare) {
                        $scope.extimePoints -= fare.totalTax.value;
                    });
                    $scope.extimePoints = Math.ceil($scope.extimePoints);
                    $scope.bookingdata.extimePoints = $scope.extimePoints;
                }

                if ((doesFlexiesPricesChanged || doesBrandedFlexyChanged) && $scope.bookingdata.flexyReference != null) {

                    if (doesFlexiesPricesChanged) {
                        mrflyWidgets["mrfly-widget-flex"].$refs.instance.$emit('selectedOffer', mrflyWidgets["mrfly-widget-flex"].$refs.instance.selectedOffer);
                    }

                    if (document.querySelector('.slide-in-fade-out').classList.contains('show')) {
                        document.querySelector('.slide-in-fade-out').classList.remove('show');
                        clearTimeout($scope.alertFlexyPriceChangedTimeout);
                    }

                    setTimeout(function () {
                        document.getElementById('i-smooth-infos-flexies').classList.remove('hide');
                        document.querySelector('.slide-in-fade-out').classList.add('show');
                    }, 200);

                    $scope.alertFlexyPriceChangedTimeout = setTimeout(function () {
                        document.getElementById('i-smooth-infos-flexies').classList.add('hide');
                    }, 7300);
                } 

            };

            $scope.getLuggageAveragePriceByPaxType = function (paxType) {
                var totalAdditionnalLuggagePrice = 0;
                if ($scope.bookingdata.bookingRequest && $scope.bookingdata.bookingRequest.passengers) {
                    $scope.bookingdata.bookingRequest.passengers.each(function (p) {
                        if (p.paxType == paxType) {
                            for (var item in p.baggageByItinerary) {
                                if (p.baggageByItinerary[item].quantity > 0)
                                    totalAdditionnalLuggagePrice += p.baggageByItinerary[item].totalPrice.value;
                            }
                        }
                    });
                }
                return totalAdditionnalLuggagePrice;
            };


            $scope.getSeatAveragePriceByPtc = function (ptc) {
                var totalAdditionnalSeatPrice = 0;
                if ($scope.enableSeatMap) {
                    $scope.selectedSeats.each(function (selectedSeat) {
                        selectedSeat.details.each(function (seat) {
                            if (seat.passenger && seat.passenger.ptc == ptc) {
                                totalAdditionnalSeatPrice += seat.price;
                            }
                        })
                    });
                }
                return totalAdditionnalSeatPrice;
            };

            //Update totals Amounts
            $scope.updateTotals = function (baseFare, includeBaggage) {
                
                //var counter = baseFare + $scope.proposal.totalFee.value + $scope.proposal.totalMargin.value;
                var counter = baseFare + $scope.bookingdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value; //Check totalFee?

                //BestPrice
                //if ($scope.bookingdata.bookingRequest.bestPriceFare && !$scope.bookingdata.bookingRequest.brandKey)
                //    counter += $scope.proposal.totalBestPrice.value; ??Manuel
                //Flexy
                if ($scope.bookingdata && $scope.bookingdata.bookingRequest && $scope.bookingdata.bookingRequest.flexyFare)
                    counter += $scope.flexyTotalPrice;

                //Insurance Annulation
                if ($scope.bookingdata.insurancesSelected.isAnnul && $scope.bookingdata.insurancesSelected.isAnnul.amount > 0) {
                    counter += $scope.bookingdata.insurancesSelected.isAnnul.amount;
                }

                //Insurance repatriament
                if ($scope.bookingdata.insurancesSelected.isRepatriament && $scope.bookingdata.insurancesSelected.isRepatriament.amount > 0) {
                    counter += $scope.bookingdata.insurancesSelected.isRepatriament.amount;
                }

                //Insurance Pack
                if ($scope.bookingdata.insurancesSelected.isAnnulRepatriament && $scope.bookingdata.insurancesSelected.isAnnulRepatriament.amount > 0) {
                    counter += $scope.bookingdata.insurancesSelected.isAnnulRepatriament.amount;
                }

                //Additional Baggages
                if ($scope.bookingdata.bookingRequest && $scope.bookingdata.bookingRequest.passengers && includeBaggage) {
                    $scope.bookingdata.bookingRequest.passengers.each(function (p) {
                        for (var item in p.baggageByItinerary) {
                            if (p.baggageByItinerary[item].quantity > 0)
                                counter += p.baggageByItinerary[item].totalPrice.value;
                        }
                    });
                }

                if ($scope.enableSeatMap) {
                    //Seats
                    $scope.selectedSeats.each(function (selectedSeat) {
                        counter += selectedSeat.price;
                    });
                }

                //TotalFlex
                if ($scope.totalFlexSelected) {
                    counter += $scope.totalFlexAmount;
                }


                //Discount Code
                if ($scope.bookingdata.discountInfo != null && $scope.bookingdata.discountInfo.applicableAmount != 0) {
                    if ($scope.bookingdata.discountInfo.discountAmount > counter) {
                        $scope.bookingdata.discountInfo.applicableAmount = - counter;
                    } else {
                        $scope.bookingdata.discountInfo.applicableAmount = - $scope.bookingdata.discountInfo.discountAmount;
                    }
                    console.log("Update $scope.bookingdata.discountInfo.applicableAmount");
                    console.log($scope.bookingdata.discountInfo);

                    counter += $scope.bookingdata.discountInfo.applicableAmount;
                }

                var val0 = tseAirResultsHelper.getNumberObject(counter);
                var val1 = tseAirResultsHelper.getNumberObject(val0.value - $scope.bookingdata.purchaseResponse.proposalsRequested.totalAgencyMargin.value);
                console.log([val0, val1]);
                return [val0, val1];
            };

            //onBookingSummaryUpdate
            $scope.$on("onBookingSummaryUpdate", function (event, args) {

                var apiToken = getCookie("authToken_" + window.site.partnerId);
                var loginId = getCookie("lid_" + $window.site.partnerId);
                var purchaseNewServairRequest = {};
                purchaseNewServairRequest.purchaseRequest = $scope.bookingdata.purchaseRequest;

                var passengers = $scope.bookingdata.bookingRequest.passengers;
                passengers.forEach(function (pax) {
                    pax.baggages = [];
                    if (pax.baggageByItinerary) {
                        Object.keys(pax.baggageByItinerary).forEach(function (key) {
                            pax.baggages.push(pax.baggageByItinerary[key]);
                        });
                    }
                });

                //recalcul des subventions par rapport aux baggages si servair et classe eco
                if ($scope.isServair && $scope.bookingdata.request.cabinClass == "Y" && $scope.bookingdata.bookingRequest.needToRecalculSubvention) {
                    $scope.bookingdata.bookingRequest.needToRecalculSubvention = false;
                    $window.FLoading({ umbracoKeyLoadingText: "loadingpage.recalculationinprogress", vAction: 'showNow' });
                    var recalculSubventionRequest = {};
                    recalculSubventionRequest.paxInfo = passengers;
                    recalculSubventionRequest.purchaseRequest = purchaseNewServairRequest.purchaseRequest;
                    recalculSubventionRequest.purchaseId = $scope.bookingdata.purchaseResponse.purchaseId;
                    recalculSubventionRequest.apiToken = apiToken;
                    recalculSubventionRequest.selectedProduct = window.sessionStorage.getItem("selectedProduct");

                    $window.Typescript.Servair.Services.ServairService.recalculSubvention(recalculSubventionRequest).then(function (result) {
                        $scope.bookingdata.purchaseResponse.proposalsRequested.totalAmount = airHelper.getNumberObject(result.data.newTotal);
                        $scope.bookingdata.purchaseResponse.proposalsRequested.totalSubventionAmount = airHelper.getNumberObject(result.data.totalSubvention);
                        $scope.updateTemplate();
                        $window.FLoading({ vAction: 'hide' });
                    });
                }
                $scope.updateTemplate();
                if (mrflyWidgets['mrfly-widget-itinerary-recap']) {
                    mrflyWidgets['mrfly-widget-itinerary-recap'].fares = $scope.bookingdata.purchaseResponse.proposalsRequested.fares;
                    mrflyWidgets['mrfly-widget-itinerary-recap'].itineraries = $scope.bookingdata.purchaseResponse.itineraries;
                }
            });
            $scope.updateTemplate();

            $scope.getCityNameOrIata = function (airportIata) {
                if (airportIata != undefined && airportIata != null) {
                    if ($scope.bookingdata.airports[airportIata].cityIata) {
                        if ($scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata]) {
                            if ($scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata].length > 1)
                                return $scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata][0].name;
                            else
                                return $scope.bookingdata.cities[$scope.bookingdata.airports[airportIata].cityIata].name;
                        }
                    }
                    else {
                        if ($scope.bookingdata.airports[airportIata].name)
                            return $scope.bookingdata.airports[airportIata].name;
                        else
                            return airportIata;
                    }
                }
            }

            //Product TO        
            if ($scope.isProductTO) {
                $scope.hasPreBook = $scope.bookingdata.purchaseResponse.preBookToDate != null;

                if ($scope.hasPreBook) {
                    $scope.ticketing = moment(($scope.bookingdata.purchaseResponse.preBookToDate), moment.ISO_8601).toDate();
                }
            }

            //Fpop
            $scope.FPop = function (_event, obj) {
                if (_event) {
                    var value = _event.target;
                    obj.oTarget = value;
                }

                $window.FPop(obj);
            };

            //Branded Fares Services included
            $scope.serviceIncludedFilter = function (item) {
                var auxService = "";
                $scope.bookingdata.purchaseResponse.proposalsRequested.services.each(function (s) {
                    if (s.index == item.index) {
                        auxService = s;
                    }
                });

                if (auxService != "")
                    return auxService.servicePricing == 0;

                return false;
            };

            $scope.getProposalIncludedServices = function () {
                var includedServices = [];
                $scope.bookingdata.purchaseResponse.proposalsRequested.services.each(function (s) {
                    if (s.servicePricing == 0) {
                        var serviceAux = $scope.bookingdata.purchaseResponse.brandedFaresServices.find(function (service) {
                            return service.index === s.index
                        });

                        includedServices.push(serviceAux);
                    }
                })

                return includedServices;
            }

            $scope.selectedFlexy = function () {
                var key = window.sessionStorage.getItem("inssurance.totalflex.selectedOffer");
                if (key) {
                    return $scope.bookingdata.purchaseResponse.proposals[0].flexies[key];
                }
                return null;
            }

            $scope.proposalIncludedServices = $scope.getProposalIncludedServices();

            //This routine gets the booking details in order to retrieve the baggages information with the new decoding and display it in AirPaymentResponseBody
            //newversion only is true en AirPaymentResponseBody

            $scope.totalBasketPrice = function (purchaseResponse) {

                if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                    // mandatory Options
                    var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                    var mandatoryOptionsFees = 0;
                    if (proposal.mandatoryFees) {
                        mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                            return accumulator + mandatoryFee.totalPrice;
                        }, 0);
                    }
                    return $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
                } else {
                    return $scope.bkgData.prixTotal.value;
                }
            };

            $scope.BindPaxForm = function (booking, accommodation, fares) {

                var b = $window.objectClone(booking);
                b.fares = fares;
                getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {

                    //nights number
                    var nights = moment(accommodation.to).diff(moment(accommodation.from), "days");
                    accommodation.nightNumber = nights;

                    mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                    mrflyWidgets['mrfly-widget-accommodation-info'].resources = i18next.store.data.dev.translation;
                    mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = accommodation;
                    mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;

                    var margin = booking.fares.filter(function (f) { return f.type === 70 && f.subtype === 901; });
                    if (margin.length > 0) {
                        mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = margin[0].amount;
                    }

                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });

                getBundleWidget("mrfly-widget-accommodation-price-detail.js").done(function () {
                    mrflyWidgets['mrfly-widget-accommodation-price-detail'].resources = i18next.store.data.dev.translation;
                    mrflyWidgets['mrfly-widget-accommodation-price-detail'].booking = b;
                    mrflyWidgets['mrfly-widget-accommodation-price-detail'].siteType = window.site.type;
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });


                getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.totalBasketPrice();
                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + b.purchaseId));
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }

                    var basketDetails = basketHelper.getPriceDetails(
                        $window.site.type,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.bkgData.accommodations,
                        siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel : { value: 0, margin: 0 },
                        extraDetails
                    );

                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                })
                .fail(function (jqxhr, settings, exception) {
                    console.log(exception);
                });
            };

            if ($scope.newversion) {

                if ($scope.bookingdata && $scope.bookingdata.bookingResponse && $scope.bookingdata.bookingResponse.bookingId) {

                    //check parent scope to see if we're mocking or in real case
                    var isMocked = $scope.$parent.bkDataMock != null;



                    if (!isMocked) {

                        $scope.apiClient.AIR.getBookingDetails([$scope.bookingdata.bookingResponse.bookingId]).success(function (data) {

                            if (data && data.status == 0) {
                                $scope.showDetail = true;
                                $scope.bkgDetails = tseAirResultsHelper.getViewForBookingDetailsEntityVersion2(data);


                                if ($scope.enableUpSellProducts === true && $scope.showaccomodationinfo === true) {

                                    var fares = [];
                                    data.bookings.each(function (b) {
                                        b.fares.each(function (f) {
                                            fares.push(f);
                                        });
                                    });

                                    //take last booking (in b2b basket the accomodation is in a 2nd one)
                                    var booking = data.bookings[data.bookings.length - 1];

                                    //in P4, we pass the accomodation loaded in the controller
                                    if ($scope.parentaccommodation != null) {
                                        $scope.BindPaxForm(booking, $scope.parentaccommodation, fares);
                                    }
                                    else if (booking.accommodations.any()) {
                                        console.log(data.bookings[0]);
                                        $scope.BindPaxForm(booking, booking.accommodations[0], fares);
                                    }
                                }

                            }
                        });
                    }
                    else {
                        if ($scope.enableUpSellProducts === true && $scope.showaccomodationinfo === true) {

                            var data = JSON.parse(window.sessionStorage.getItem("bookinkDetailMock"));
                            if (!data.bookings[0].accommodations[0]) {
                                data.bookings[0].accommodations[0] = data.bookings[1].accommodations[0];
                                data.bookings[1].fares.forEach((fare) => {
                                    data.bookings[0].fares.push(fare);
                                });
                            }

                            $scope.BindPaxForm(data.bookings[0], data.bookings[0].accommodations[0], data.bookings[0].fares);
                        }
                    }
                }
            }
        };
        return directive;
    }]);